<script setup>
import Datatable from '@/components/admin/utilities/datatable.vue';
import SortableTh from '@/components/admin/datatable/sortableTh.vue';
import { ref, reactive } from "vue";
import QuotesDrawer from "@/components/admin/quotes/quotesDrawer.vue";

const props = defineProps({
    ajaxUrl: String,
    statuses: {
        type: Object,
        default: {},
    },
    columns: Array,
});

const orderBy = ref('id');
const orderAsc = ref(false);
const limit = ref(50);
const searchTerm = ref(null);

const setOrder = function (input) {
    if (orderBy.value == input) {
        orderAsc.value = !orderAsc.value;
    } else {
        orderBy.value = input;
        orderAsc.value = true;
    }
};
</script>

<template>
    <Datatable
        :url="ajaxUrl"
        :limit="limit"
        :orderBy="orderBy"
        :orderAsc="orderAsc"
        :search="searchTerm"
        refreshOn="quotes-list:refresh"
    >
        <template #headings>
            <SortableTh
                v-if="columns.includes('id')"
                v-on:set-order-by="setOrder"
                :currentOrderBy="orderBy"
                :orderAsc="orderAsc"
                orderBy="id"
            >ID</SortableTh>
            <SortableTh
                v-if="columns.includes('xero_quote_number')"
                v-on:set-order-by="setOrder"
                :currentOrderBy="orderBy"
                :orderAsc="orderAsc"
                orderBy="xero_quote_number"
            >Quote Number</SortableTh>
            <SortableTh
                v-if="columns.includes('order')"
                :currentOrderBy="orderBy"
            >Order</SortableTh>
            <SortableTh
                v-if="columns.includes('customer')"
                :currentOrderBy="orderBy"
            >Customer</SortableTh>
            <SortableTh
                v-if="columns.includes('expires_at')"
                :currentOrderBy="orderBy"
                v-on:set-order-by="setOrder"
                :orderAsc="orderAsc"
                orderBy="expires_at"
            >Expires</SortableTh>
            <SortableTh
                v-if="columns.includes('status')"
                v-on:set-order-by="setOrder"
                :currentOrderBy="orderBy"
                :orderAsc="orderAsc"
                width="15%"
                orderBy="status"
                class="text-center"
            >Status</SortableTh>
            <SortableTh
                v-if="columns.includes('drafted_by_user')"
                v-on:set-order-by="setOrder"
                :current-order-by="orderBy"
                :order-asc="orderAsc"
                order-by="drafted_by_user"
            >Created By</SortableTh>
            <SortableTh
                v-if="columns.includes('created_at')"
                v-on:set-order-by="setOrder"
                :current-order-by="orderBy"
                :order-asc="orderAsc"
                order-by="created_at"
            >Created On</SortableTh>
            <SortableTh
                v-if="columns.includes('loss_reason')"
                :currentOrderBy="orderBy"
            >Reason</SortableTh>
            <th v-if="columns.includes('actions')"></th>
        </template>
        <template #rows="{ data }">
            <tr v-for="quote in data"
                :key="quote.id">
                <td v-if="columns.includes('id')" class="text-nowrap">
                    <QuotesDrawer v-if="quote.quick_view_url" :source-url="quote.quick_view_url"></QuotesDrawer>
                    {{ quote.id }}
                </td>
                <td v-if="columns.includes('xero_quote_number')">{{ quote.xero_quote_number }}</td>
                <td v-if="columns.includes('order')"
                    v-html="quote.order"></td>
                <td v-if="columns.includes('customer')"
                    v-html="quote.customer"></td>
                <td v-if="columns.includes('expires_at')">{{ quote.expires_at }}</td>
                <td v-if="columns.includes('status')"
                    class="text-center"
                    v-html="quote.status"></td>
                <td v-if="columns.includes('drafted_by_user')">{{ quote.drafted_by_user }}</td>
                <td v-if="columns.includes('created_at')">{{ quote.created_at }}</td>
                <td v-if="columns.includes('loss_reason')">{{ quote.loss_reason }}</td>
                <td v-if="columns.includes('actions')"
                    v-html="quote.actions"></td>
            </tr>
        </template>
    </Datatable>
</template>
