<template>
    <small class="text-muted d-flex gap-2">
        <span v-if="label">
            {{ label }}:
        </span>
        <i class="fa fa-plus text-primary mt-1" role="button" @click="edit()"></i>
        <div class="d-flex">
            <div v-for="(item, index) in _items" :key="item"
                class="d-flex align-items-center gap-1">
                {{ item }}
                <i class="fa fa-pencil text-primary mt-1" role="button" @click="edit(item)"></i>
                <i class="fa fa-times text-danger mt-1" role="button" @click="remove(item)"></i>
                <span class="mx-1" v-if="index !== _items.length - 1">|</span>
            </div>
        </div>
    </small>
    <input v-if="name" type="hidden" :name="name" :value="serialisedItems" />
</template>

<script setup>
    import { ref, computed } from "vue";
    import debounce from 'lodash/debounce';
    import { useFetch } from "@/utilities/useFetch.js";

    const emit = defineEmits(['update:modelValue']);
    const props = defineProps({
        label: String,
        required: Boolean,
        name: String,
        items: Array,
        validateAsEmail: Boolean
    });

    const _items = ref(props.items);
    const serialisedItems = computed(() => _items.value.join(','));

    const edit = async function (item) {
        let original = item;
        const { value: newValue } = await Swal.fire({
            target: document.getElementById('page-container'),
            icon: 'info',
            title: 'Enter new value',
            showCancelButton: true,
            customClass: {
                confirmButton: 'bg-primary',
            },
            reverseButtons: true,
            focusConfirm: false,
            input: "text",
            inputValue: original,
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (typeof value === 'string' && value.length > 0) {
                        if (props.validateAsEmail) {
                            // Basic email validation
                            if (!/^.+@.+\..+$/.test(value)) {
                                resolve("Please enter a valid email address.");
                            }
                        }
                        resolve();
                    } else {
                        resolve("Please enter a valid value.");
                    }
                });
            }
        });
        if (newValue && newValue !== original) {
            if (original === undefined) {
                _items.value.push(newValue);
            } else {
                _items.value = _items.value.map(i => i === original ? newValue : i);
            }
        }
    }

    const remove = function (item) {
        _items.value = _items.value.filter(i => i !== item);
    }
</script>
