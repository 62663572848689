<template>
    <tr>
        <template v-if="item.parent">
            <td colspan="999">
                <div class="d-flex gap-3 ps-4">
                    <img class="img-thumbnail object-fit-cover thumbnail-xs"
                         :src="item.part.main_image ? item.part.main_image : partPlaceholderImage" />
                    <div class="d-flex flex-column gap-1">
                        {{ item.part.label }}
                        <span>Qty Per Kit: {{ item.quantity }}</span>
                    </div>
                </div>
            </td>
        </template>

        <template v-else>
            <td style="max-width: 200px;">
                <input type="hidden"
                       :value="item.id" />
                <PartFormSelect
                    v-model="item.part">
                </PartFormSelect>
                <SkuField v-if="typeof item.part !== 'undefined' && !isExistingPart"
                          v-model="item.newPartSKU"
                          :sku-check-url="skuCheckUrl"
                          :item-skus="itemSkus" />
            </td>
            <td class="text-center quantity">
                <input class="form-control text-center m-auto qty-input"
                       type="text"
                       inputmode="numeric"
                       pattern="[0-9]*"
                       placeholder="1"
                       v-model="item.quantity" />
            </td>
            <td style="min-width: 140px;">
                <template v-if="isPartUsed">
                    N/A
                </template>
                <div v-else-if="!isExistingPart" class="input-group">
                    <span class="input-group-text">
                        <i v-if="['EUR', 'USD', 'GBP'].includes(currency.code)"
                           class="fas"
                           :class="{ 'fa-euro-sign': currency.code === 'EUR', 'fa-dollar-sign': currency.code === 'USD', 'fa-pound-sign': currency.code === 'GBP' }">
                        </i>
                        <template v-else>
                            {{ currency.code }}
                        </template>
                    </span>
                    <input class="form-control"
                           type="text"
                           inputmode="numeric"
                           pattern="\d+(\.\d{1,2})?"
                           title="Price in pounds, e.g. 95.50"
                           placeholder="0"
                           v-model="item.rrp">
                </div>
                <template v-else>
                    {{ currency.symbol }}{{ priceFormat((item.rrp ?? 0) * currency.rate) }}
                </template>
            </td>
            <td style="min-width: 140px;">
                <div v-if="isPartUsed">
                    N/A
                </div>
                <div v-else class="input-group">
                    <input class="form-control"
                           type="text"
                           inputmode="numeric"
                           pattern="\d+"
                           title="Percentage discount, e.g. 5"
                           placeholder="0"
                           v-model="item.discount">
                    <span class="input-group-text">
                        <i class="fa fa-percent"></i>
                    </span>
                </div>
            </td>
            <td>
                <div class="d-flex w-100 justify-content-center">
                    <Toggle
                        classes="text-center m-auto"
                        v-model="isPartUsed"
                        :disabled="isExistingPart"
                    />
                </div>
            </td>
            <td>
                <i class="fas fa-times text-danger"
                   role="button"
                   @click="emit('removeItem', index)"></i>
            </td>
        </template>
    </tr>
</template>

<script setup>
    import {computed, ref, watch} from 'vue';
    import PartFormSelect from '@/components/admin/utilities/partFormSelect.vue';
    import SkuField from "@/components/admin/utilities/SkuField.vue";
    import Toggle from "@/components/admin/utilities/toggle.vue";
    import {priceFormat} from "@/utilities/priceFormat.js";

    const emit = defineEmits(['removeItem', 'update:unitNet', 'is-invalid']);
    const props = defineProps({
        item: Object,
        index: [String, Number],
        partsUrl: String,
        skuCheckUrl: {
            type: String,
            required: true,
        },
        itemSkus: {
            type: Array,
            default: () => [],
        },
        currency: {
            type: Object,
            default: () => ({ code: 'GBP', rate: 1, symbol: '£' }),
            validator: (propValue) => {
                return propValue.hasOwnProperty('code') && propValue.hasOwnProperty('rate') && propValue.hasOwnProperty('symbol');
            },
        },
    });

    const partPlaceholderImage = window.placeholderImage;
    const isExistingPart = computed(() => typeof props.item.part === 'object');
    const isPartUsed = ref(!!props.item.is_used ?? false);

    watch(() => isPartUsed.value, (newValue) => {
        props.item.is_used = newValue;
    }, { immediate: true });

    watch(() => props.item.part, (newValue) => {
        if (typeof newValue === 'string' && newValue.length > 0 && !props.item.newPartSKU) {
            props.item.newPartSKU = newValue;
            props.item.part = '';
        }
        if (typeof newValue === 'object') {
            props.item.rrp = newValue.retail_price;
            isPartUsed.value = newValue.is_used;
        } else {
            isPartUsed.value = false;
        }
    }, {
        immediate: true,
    });
</script>
