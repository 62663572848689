<template>
    <div class="mb-4">
        <label for="courier"
               class="control-label required">Courier</label>
        <imageSelect name="courier"
                     v-model="courier"
                     @update:modelValue="updateParentWithValues"
                     :url="routes.couriers">
        </imageSelect>
    </div>
    <div class="mb-4">
        <label class="control-label"
               for="trackingNumber">Tracking number</label>
        <input type="text"
               class="form-control"
               id="trackingNumber"
               name="trackingNumber"
               v-model="trackingNumber"
               @change="updateParentWithValues">
        <p class="help-block text-muted"
           v-if="trackingUrl">{{ trackingUrl }}</p>
    </div>
    <div class="mb-4">
        <label class="control-label"
               for="actualDeliveryPrice">Actual delivery price</label>
        <div class="input-group">
            <span class="input-group-text">
                <i class="fas fa-pound-sign"></i>
            </span>
            <input type="text"
                class="form-control"
                id="actualDeliveryPrice"
                name="actualDeliveryPrice"
                placeholder="Delivery price in pounds, e.g. 95.50"
                v-model="actualDeliveryPrice"
                @change="updateParentWithValues">
        </div>
    </div>

    <div class="mb-4">
        <label class="control-label"
               for="total_charged_delivery">Total Charged Delivery <small class="text-muted">(inc. VAT)</small></label>
        <div class="input-group">
            <span class="input-group-text">
                <i class="fas fa-pound-sign"></i>
            </span>
            <input type="text"
                   class="form-control"
                   id="total_charged_delivery"
                   name="total_charged_delivery"
                   :value="totalDeliveryPrice"
                   disabled
            >
        </div>
    </div>
    <div v-if="extras.preselected.courier"
         class="form-check form-switch mb-4">
        <label class="form-check-label"
               for="resend_email">Resend customer email</label>
        <input class="form-check-input"
               type="checkbox"
               id="resend_email"
               v-model="resendEmail"
               @change="updateParentWithValues">
    </div>
</template>

<script setup>
import {ref, computed} from "vue";
import imageSelect from '@/components/admin/utilities/imageSelect.vue';

const emit = defineEmits(['stepValueChanged']);

const props = defineProps({
    routes: Object,
    extras: Object,
    stepsData: Object,
});

const courier = ref(props.extras.preselected.courier);
const trackingNumber = ref(props.extras.preselected.trackingNumber);
const actualDeliveryPrice = ref(props.extras.preselected.actualDeliveryPrice);
const resendEmail = ref(false);

const trackingUrl = computed(() => courier.value?.tracking_url ?
    (trackingNumber.value ? (new URL(courier.value.tracking_url.replace('[TRACKING NUMBER]', trackingNumber.value))).toString() : courier.value.tracking_url)
    : null);
const totalDeliveryPrice = computed(() => {
    let total = 0;

    if (!props.stepsData.pickings?.pickings) {
        return total.toFixed(2);
    }

    // Unique list of orders. Same order could be in multiple picks - we don't want to add it multiple times
    const orderList = {};
    for (const picking of Object.values(props.stepsData.pickings?.pickings)) {
        orderList[picking.pickable] = parseFloat(picking.delivery_total);
    }
    for (const orderDeliveryTotal of Object.values(orderList)) {
        if (! isNaN(orderDeliveryTotal)) {
            total += orderDeliveryTotal;
        }
    }

    return total.toFixed(2);
});

function updateParentWithValues() {
    emit('stepValueChanged', {
        courier: courier.value,
        trackingNumber: trackingNumber.value,
        actualDeliveryPrice: actualDeliveryPrice.value,
        resendEmail: resendEmail.value,
    });
}
</script>
