<template>
    <div class="d-flex justify-content-between wizard-header position-relative">
        <div class="border-top spacer-line"></div>
        <template v-for="(step, i) in steps">
            <div :role="i != currentStep && isAllPreviousStepsValid(i) ? 'button' : 'none'"
                 :class="{
                     'd-flex flex-column align-items-center progress-number-wrapper': true,
                     'active': currentStep == i
                 }"
                 @click="attemptGoToStep(i)">
                <div class="progress-number-inner-wrapper">
                    <span class="d-flex align-items-center justify-content-center border border-2 p-2 progress-number">
                        <i v-if="isAllPreviousStepsValid(i) && i != currentStep && (i < currentStep || steps[i].validate())"
                           class="fa fa-check text-flat"></i>
                        <span v-else>{{ i }}</span>
                    </span>
                </div>
                <span class="fs-xs text-center">{{ step.label }}</span>
            </div>
        </template>
    </div>

    <div class="mt-4 mb-3">
        <div v-show="currentStep == 1">
            <CustomerStep
                :routes="routes"
                :extras="extras"
                :steps-data="stepsData"
                :is-from-quote="isFromQuote"
                @step-value-changed="(data) => setStepData('customer', data)"
            />
        </div>
        <div v-show="currentStep == 2">
            <AddressStep
                :routes="routes"
                :extras="extras"
                :steps-data="stepsData"
                :is-from-quote="isFromQuote"
                @step-value-changed="(data) => setStepData('addresses', data)"
            />
        </div>
        <div v-show="currentStep == 3">
            <ItemsStep
                :routes="routes"
                :extras="extras"
                :steps-data="stepsData"
                :unit-price-editable="unitPriceEditable"
                :is-from-quote="isFromQuote"
                @step-value-changed="(data) => setStepData('items', data)"
            />
        </div>
        <div v-show="currentStep == 4">
            <ExtraStep
                :routes="routes"
                :extras="extras"
                :steps-data="stepsData"
                @step-value-changed="(data) => setStepData('extra', data)"
            />
        </div>
        <div v-if="currentStep == 5">
            <SummaryStep
                :routes="routes"
                :extras="extras"
                :steps-data="stepsData"
                :unit-price-editable="unitPriceEditable"
            />
        </div>
    </div>

    <div class="pb-3">
        <div class="d-flex justify-content-end gap-2">
            <div v-if="!bothAddressesHaveCountry && currentStep == 2" class="d-flex align-items-center gap-2">
                <i class="fa fa-exclamation-triangle"></i>
                <span>Please ensure both addresses have a country selected</span>
            </div>
            <button v-if="currentStep > 1"
                    type="button"
                    class="btn btn-secondary"
                    @click="previousStep">Back</button>
            <button v-if="currentStep == Object.keys(steps).length"
                    type="button"
                    class="btn btn-primary"
                    :disabled="!steps[currentStep].validate()"
                    @click="requestComplete"
                    ref="submitButtonRef">Complete</button>
            <button v-else
                    type="button"
                    class="btn btn-primary"
                    :disabled="!steps[currentStep].validate()"
                    @click="nextStep">Next</button>
        </div>
        <div class="text-end mt-2">
            <div ref="errorContainerRef"></div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, nextTick, computed } from "vue";
import debounce from 'lodash/debounce';
import CustomerStep from "@/components/admin/customerOrders/formWizard/customerStep.vue";
import AddressStep from "@/components/admin/customerOrders/formWizard/addressStep.vue";
import ItemsStep from "@/components/admin/customerOrders/formWizard/itemsStep.vue";
import ExtraStep from "@/components/admin/customerOrders/formWizard/extraStep.vue";
import SummaryStep from "@/components/admin/customerOrders/formWizard/summaryStep.vue";

const props = defineProps({
    routes: Object,
    extras: Object,
    unitPriceEditable: Boolean,
    isFromQuote: Boolean,
});

let steps = {
    1: {
        label: 'Customer',
        validate: function () {
            if (stepsData.customer) {
                return true;
            }
            return false;
        }
    },
    2: {
        label: 'Addresses',
        validate: function () {
            // Ensure billing & delivery addresses are present with at least 1 field set.
            if (!stepsData.addresses) {
                return false;
            }

            if (!stepsData.addresses.billing || !stepsData.addresses.billing.country) {
                return false;
            }

            if (!stepsData.addresses.delivery || !stepsData.addresses.delivery.country) {
                return false;
            }
            return true;
        }
    },
    3: {
        label: 'Items',
        validate: function () {
            const invalidItems = stepsData.items.items.filter((i) => i.newPartSKU === '');
            if (!stepsData.items || !stepsData.items.items || stepsData.items.items.filter((item) => !item.is_delivery).length == 0 || invalidItems.length > 0) {
                return false;
            }
            return true;
        }
    },
    4: {
        label: 'Extra',
        validate: function () {
            return true;
        }
    },
    5: {
        label: 'Summary',
        validate: function () {
            return true;
        }
    },
};

const currentStep = ref(1);
const stepsData = reactive({});
const submitButtonRef = ref(null);
const errorContainerRef = ref(null);
const form = document.getElementById('wizard_form');
const bothAddressesHaveCountry = ref(false);

const previousStep = function () {
    currentStep.value--;
}
const nextStep = function () {
    if (currentStep.value == 3) {
        if (
            (stepsData.items.items.filter((item) => item.is_delivery).length == 0) ||
            (stepsData.items.items.filter((item) => item.is_delivery)[0].unit_net == 0)
        ) {
            confirmFreeDelivery();
            return;
        }
    }
    currentStep.value++;
}
const attemptGoToStep = function (requestedStep) {
    if (requestedStep == currentStep.value) {
        return false;
    }
    if (!isAllPreviousStepsValid(requestedStep)) {
        return false;
    }
    currentStep.value = requestedStep;

}

const isAllPreviousStepsValid = function (stepNumber) {
    for (let i = 1; i < stepNumber; i++) {
        if (!steps[i].validate()) {
            return false;
        }
    }
    return true;
}

const setStepData = function (step, data) {
    stepsData[step] = data;
    bothAddressesHaveCountry.value = stepsData.addresses?.billing?.country && stepsData.addresses?.delivery?.country;
}

const requestComplete = function () {
    for (const [key, step] of Object.entries(steps)) {
        if (!step.validate()) {
            return false;
        }
    }

    completeWizard();
}

const completeWizard = debounce(ajaxSteps, 350);

async function ajaxSteps() {
    let data = JSON.parse(JSON.stringify(stepsData));
    data.items.items = data.items.items.filter((item) => !item.parent);
    data.submitter = submitButtonRef.value;
    form.dispatchEvent(new CustomEvent('ajax-submit', {
        detail: data
    }));
}

function getLastValidStepNumber() {
    for (let i = 1; i < Object.keys(steps).length; i++) {
        if (!steps[i].validate()) {
            return i;
        }
    }
    return Object.keys(steps).length;
}

function confirmFreeDelivery() {
    Swal.fire({
        target: document.getElementById('page-container'),title: 'Free Delivery',
        text: 'Would you like to offer free delivery on this order?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result) => {
        if (result.isConfirmed) {
            currentStep.value++;
        }
    });
}

nextTick(function () {
    currentStep.value = getLastValidStepNumber();
    if (currentStep.value > 2 && props.extras.customerWasPreselected) {
        currentStep.value = 2;
    }form.ajaxForm({
        validationTarget: errorContainerRef.value,
    });
});
</script>
