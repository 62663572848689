<template>
    <div class="form-group mb-4">
        <label for="comments"
               class="control-label">Customer Order Comments</label>
        <textarea class="form-control"
                  name="comments"
                  rows="5"
                  v-model="comments"
                  id="comments">
        </textarea>
    </div>
    <div class="form-group mb-4">
        <label for="internal_comments"
               class="control-label">Internal Comments</label>
        <textarea class="form-control"
                  name="internal-comments"
                  rows="5"
                  v-model="internalComments"
                  id="internal_comments">
        </textarea>
    </div>
    <div class="form-group">
        <label for="legacy_customer_order_id"
               class="control-label">Legacy Checkout ID</label>
        <input class="form-control"
               type="text"
               name="legacy-customer-order-id"
               v-model="legacyCustomerOrderId"
               pattern="\d+"
               inputmode="numeric"
               id="legacy_customer_id" />
        <p class="help-block text-muted mb-0">Can be found in legacy admin URL:
            https://www.nytractorparts.co.uk/admin/app/checkout-checkout/<b>legacy-checkout-id</b>/edit</p>
        <div class="text-danger mt-2"
             v-if="isLegacyCustomerOrderIdInvalid">Please enter a valid number</div>
    </div>
</template>

<script setup>
    import { ref, watch, computed } from "vue";

    const emit = defineEmits(['step-value-changed']);
    const props = defineProps({
        routes: Object,
        extras: Object,
        stepsData: Object,
        isInitialLoadingFinished: Boolean,
    });

    let comments = ref(props.extras.preselected.comments ? props.extras.preselected.comments : '');
    let internalComments = ref(props.extras.preselected.internalComments ? props.extras.preselected.internalComments : '');
    let legacyCustomerOrderId = ref(props.extras.preselected.legacyCustomerOrderId ? props.extras.preselected.legacyCustomerOrderId : '');

    watch(() => comments.value, () => updateParentWithValues());
    watch(() => internalComments.value, () => updateParentWithValues());
    watch(() => legacyCustomerOrderId.value, (newValue) => {
        if (isLegacyCustomerOrderIdInvalid.value) {
            return;
        }
        updateParentWithValues();
    });

    const isLegacyCustomerOrderIdInvalid = computed(() => {
        return legacyCustomerOrderId.value &&
            (isNaN(legacyCustomerOrderId.value)
            || isNaN(parseFloat(legacyCustomerOrderId.value))
            || (typeof legacyCustomerOrderId.value === 'string' && legacyCustomerOrderId.value.includes('.')));
    });

    const updateParentWithValues = function () {
        let stepData = {
            comments: comments.value,
            internalComments: internalComments.value,
            legacyCustomerOrderId: legacyCustomerOrderId.value,
        };

        emit('step-value-changed', stepData);
    }
    updateParentWithValues();
</script>
