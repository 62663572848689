<template>
    <div class="d-flex align-items-center"
         :class="{
            'justify-content-center': centerWithOffset,
            'center-with-offset': editable && centerWithOffset,
        }">
        <template v-if="editable">
            <i v-if="isLoading"
            class="fa fa-fw fa-spin fa-spinner fs-sm me-1"></i>
            <i v-else
            class="fa fa-fw fa-pencil-alt fs-sm me-1 text-primary change-sold-by"
            role="button"
            @click="showModal"></i>
        </template>
        <slot></slot>
    </div>
</template>

<script setup>
    import { ref } from "vue";
    import { useFetch } from "@/utilities/useFetch.js";
    import { useVModel } from "@vueuse/core";
    import debounce from 'lodash/debounce';

    const emit = defineEmits(["update:modelValue"])

    const props = defineProps({
        modelValue: {
            type: [String, Number],
            required: true,
        },
        editUrl: {
            type: String,
            required: true,
        },
        modalTitle: {
            type: String,
            default: 'Update value?',
        },
        modalDescription: {
            type: String,
            default: 'Select new value',
        },
        centerWithOffset: Boolean,
        reloadOnSuccess: Boolean,
        editable: Boolean,
    });

    const model = useVModel(props, 'modelValue', emit);
    const isLoading = ref(false);

    let inputType = 'text';
    let fetchAbort, fetchAbortSignal;
    const _showModal = async function () {
        isLoading.value = true;

        // abort the old one if there was one
        if (fetchAbort !== undefined) {
            fetchAbort.abort();
        }
        // reinitialise the abort controller for each new request
        if ("AbortController" in window) {
            fetchAbort = new AbortController;
            fetchAbortSignal = fetchAbort.signal;
        }

        const res = await useFetch(props.editUrl, {
            method: 'GET',
            signal: fetchAbortSignal
        });

        let response = await res.json();
        inputType = response.type;

        isLoading.value = false;
        await Swal.fire({
            target: document.getElementById('page-container'),
            icon: 'question',
            title: props.modalTitle,
            text: props.modalDescription,
            showCancelButton: true,
            customClass: {
                confirmButton: 'bg-primary',
            },
            reverseButtons: true,
            focusConfirm: false,
            inputValue: model.value,
            input: inputType,
            inputAttributes: response.attributes,
            inputOptions: response.options,
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (value === '') {
                        resolve('Please select a value');
                    }
                    if (inputType === 'number' && typeof value !== 'number') {
                        value = parseFloat(value);
                    }
                    if (value === model.value) {
                        resolve('New value matches the current value. Please choose another value.');
                    } else {
                        resolve(updateValue(value));
                    }
                });

            }
        });
    }
    const showModal = debounce(_showModal, 300);

    const updateValue = async function (newValue) {
        // abort the old one if there was one
        if (fetchAbort !== undefined) {
            fetchAbort.abort();
        }
        // reinitialise the abort controller for each new request
        if ("AbortController" in window) {
            fetchAbort = new AbortController;
            fetchAbortSignal = fetchAbort.signal;
        }

        try {
            const res = await useFetch(props.editUrl, {
                method: 'PUT',
                body: {
                    value: newValue,
                },
                signal: fetchAbortSignal
            });
            if (res.ok) {
                model.value = newValue;
                if (props.reloadOnSuccess) {
                    window.location.reload();
                }
                return false; // Indicates that SWAL should close
            }
            let response = await res.json();
            return response.message;
        } catch (err) {
            console.err(err);
            return 'An error occurred. Please try again.';
        }
    }
</script>

<style lang="scss">
    .center-with-offset {
        margin-left: -21px;
    }
</style>
