
<script setup>
import Datatable from '@/components/admin/utilities/datatable.vue';
import SortableTh from '@/components/admin/datatable/sortableTh.vue';
import {reactive, ref} from "vue";
import QuotesDrawer from "@/components/admin/quotes/quotesDrawer.vue";
import CustomerOrderDrawer from "@/components/admin/customerOrders/customerOrderDrawer.vue";
import TableFilter from "@/components/admin/datatable/tableFilter.vue";
import DateFilter from "@/components/admin/datatable/DateFilter.vue";

const props = defineProps({
    ajaxUrl: {
        type: String,
        required: true,
    },
    columns: {
        type: Array,
        default: () => [],
    },
    draftedByUsers: {
        type: Array,
        default: () => [],
    },
});

const orderBy = ref('id');
const orderAsc = ref(false);
const limit = ref(50);
const searchTerm = ref(null);
const customFilter = reactive({});

const setOrder = function (input) {
    if (orderBy.value == input) {
        orderAsc.value = !orderAsc.value;
    } else {
        orderBy.value = input;
        orderAsc.value = true;
    }
};

function filterChanged(key, value) {
    if (customFilter[key] == value || !value) {
        delete customFilter[key];
    } else {
        customFilter[key] = value;
    }
}
</script>

<template>
    <div class="row mb-4 datatable-options">
        <div class="col-md-2">
            <select class="form-select" v-model.number="limit">
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
            </select>
        </div>
        <div class="col ms-auto mt-2 mt-md-0">
            <div class="input-group">
                <input type="text"
                       class="form-control"
                       v-model="searchTerm"
                       placeholder="Search all quotes..">
                <span class="input-group-text bg-body">
                    <i class="fa fa-search"></i>
                </span>
            </div>
            <div class="d-flex gap-2 mt-2 justify-content-end">
                <TableFilter
                    label="Created by"
                    :options="draftedByUsers"
                    @change="(value, label) => filterChanged('drafted_by_user', value)"
                />
                <DateFilter
                    label="Created on"
                    range
                    @change="(value, label) => filterChanged('created_at', value)"
                />
            </div>
        </div>
    </div>

    <Datatable
        :url="ajaxUrl"
        :limit="limit"
        :order-by="orderBy"
        :order-asc="orderAsc"
        :search="searchTerm"
        :customFilter="customFilter"
        refresh-on="quotes-list:refresh"
    >
        <template #headings>
            <SortableTh
                v-if="columns.includes('id')"
                v-on:set-order-by="setOrder"
                :current-order-by="orderBy"
                :order-asc="orderAsc"
                order-by="id"
            >ID</SortableTh>
            <SortableTh
                v-if="columns.includes('xero_quote_number')"
                v-on:set-order-by="setOrder"
                :current-order-by="orderBy"
                :order-asc="orderAsc"
                order-by="xero_quote_number"
            >Quote Number</SortableTh>
            <SortableTh
                v-if="columns.includes('order')"
                :current-order-by="orderBy"
            >Order</SortableTh>
            <SortableTh
                v-if="columns.includes('customer')"
                :current-order-by="orderBy"
            >Customer</SortableTh>
            <SortableTh
                v-if="columns.includes('expires_at')"
                :current-order-by="orderBy"
                v-on:set-order-by="setOrder"
                :order-asc="orderAsc"
                order-by="expires_at"
            >Expires</SortableTh>
            <SortableTh
                v-if="columns.includes('status')"
                v-on:set-order-by="setOrder"
                :current-order-by="orderBy"
                :order-asc="orderAsc"
                width="15%"
                order-by="status"
                class="text-center"
            >Status</SortableTh>
            <SortableTh
                v-if="columns.includes('drafted_by_user')"
                v-on:set-order-by="setOrder"
                :current-order-by="orderBy"
                :order-asc="orderAsc"
                order-by="drafted_by_user"
            >Created By</SortableTh>
            <SortableTh
                v-if="columns.includes('created_at')"
                v-on:set-order-by="setOrder"
                :current-order-by="orderBy"
                :order-asc="orderAsc"
                order-by="created_at"
            >Created On</SortableTh>
            <SortableTh
                v-if="columns.includes('loss_reason')"
                :current-order-by="orderBy"
            >Reason</SortableTh>
            <th v-if="columns.includes('actions')"></th>
        </template>
        <template #rows="{ data }">
            <tr v-for="quote in data"
                :key="quote.id">
                <td v-if="columns.includes('id')" class="text-nowrap">
                    <span class="me-1" v-if="quote.quick_view_url">
                        <QuotesDrawer :source-url="quote.quick_view_url"></QuotesDrawer>
                    </span>
                    {{ quote.id }}
                </td>
                <td v-if="columns.includes('xero_quote_number')">{{ quote.xero_quote_number }}</td>
                <td v-if="columns.includes('order')" class="text-nowrap">
                    <span class="me-1" v-if="quote.customer_order_quick_view_url">
                        <CustomerOrderDrawer :source-url="quote.customer_order_quick_view_url"></CustomerOrderDrawer>
                    </span>
                    <span v-html="quote.order"></span>
                </td>
                <td v-if="columns.includes('customer')"
                    v-html="quote.customer"></td>
                <td v-if="columns.includes('expires_at')">{{ quote.expires_at }}</td>
                <td v-if="columns.includes('status')"
                    class="text-center"
                    v-html="quote.status"></td>
                <td v-if="columns.includes('drafted_by_user')">{{ quote.drafted_by_user }}</td>
                <td v-if="columns.includes('created_at')">{{ quote.created_at }}</td>
                <td v-if="columns.includes('loss_reason')">{{ quote.loss_reason }}</td>
                <td v-if="columns.includes('actions')"
                    v-html="quote.actions"></td>
            </tr>
        </template>
    </Datatable>
</template>
