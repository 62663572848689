<template>
    <div class="block block-rounded">
        <div class="block-header block-header-default d-flex">
            <h3 class="block-title">{{ title }}</h3>
        </div>
        <div class="block-content d-flex flex-column gap-2">
            <template v-if="allItems.length > 0">
                <table v-if="isLargeScreen"
                       class="table table-bordered table-striped table-vcenter items-table">
                    <thead>
                        <tr>
                            <th>Part</th>
                            <th class="text-center">Qty</th>
                            <th class="text-center">{{ locationColumnHeader }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ListItem
                            v-for="(item, i) in allItems"
                            :key="i"
                            @remove-picking="(uuid) => emit('remove-picking', uuid)"
                            :item="item"
                        />
                    </tbody>
                </table>
                <div v-else>
                    <div class="d-flex flex-column gap-2 mb-4">
                        <ListItem
                            v-for="(item, i) in allItems"
                            :key="i"
                            @remove-picking="(uuid) => emit('remove-picking', uuid)"
                            :item="item"
                            :showBorder="i !== allItems.length - 1"
                            mobile-layout
                        />
                    </div>
                </div>
            </template>
            <p v-else class="text-center">{{ emptyMessage }}</p>
        </div>
    </div>
</template>

<script setup>
    import ListItem from '@/components/admin/picking/alreadyPicked/listItem.vue'
    import {ref, toRef} from "vue";

    const emit = defineEmits(['remove-picking']);
    const props = defineProps({
        items: {
            type: Array,
            default: () => [],
        },
        wording: {
            type: String,
            default: 'picking',
            validator: (propValue) => {
                return ['picking', 'allocation'].includes(propValue);
            }
        }
    });

    const allItems = toRef(props, "items");
    const isLargeScreen = window.isLargeScreen;

    let title, emptyMessage, locationColumnHeader;
    if (props.wording === 'picking') {
        title = 'Picked';
        emptyMessage = 'You haven\'t picked any items yet.';
        locationColumnHeader = 'Picked From';
    } else {
        title = 'Allocated';
        emptyMessage = 'You haven\'t allocated any items yet.';
        locationColumnHeader = 'Allocated To';
    }
</script>
