<script setup>
import Datatable from '@/components/admin/utilities/datatable.vue';
import TableFilter from '@/components/admin/datatable/tableFilter.vue';
import SortableTh from '@/components/admin/datatable/sortableTh.vue';
import { ref, reactive } from "vue";

const props = defineProps({
    ajaxUrl: String,
});

const orderBy = ref('title');
const orderAsc = ref(true);
const limit = ref(50);
const searchTerm = ref(null);
const customFilter = reactive({});

function filterChanged(key, value) {
    if (customFilter[key] == value) {
        delete customFilter[key];
    } else {
        customFilter[key] = value;
    }
}

const setOrder = function (input) {
    if (orderBy.value == input) {
        orderAsc.value = !orderAsc.value;
    } else {
        orderBy.value = input;
        orderAsc.value = true;
    }
};
</script>

<template>
    <div class="row mb-4 datatable-options">
        <div class="col-md-2">
            <select class="form-select"
                    v-model.number="limit">
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
            </select>
        </div>
        <div class="col ms-auto mt-2 mt-md-0">
            <div class="input-group">
                <input type="text"
                       class="form-control"
                       v-model="searchTerm"
                       placeholder="Search all categories..">
                <span class="input-group-text bg-body">
                    <i class="fa fa-search"></i>
                </span>
            </div>
            <div class="d-flex gap-2 mt-2 justify-content-end">
                <TableFilter
                    label="Categories"
                    :options="[{ value: 0, label: 'Regular' }, { value: 1, label: 'Landing' }]"
                    @change="(value, label) => filterChanged('is-landing-category', value)"
                />
            </div>
        </div>
    </div>

    <Datatable :url="ajaxUrl"
               :limit="limit"
               :orderBy="orderBy"
               :orderAsc="orderAsc"
               :search="searchTerm"
               :customFilter="customFilter"
               refreshOn="categories-list:refresh">
        <template #headings>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="title">Title</SortableTh>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="is_active"
                        class="text-center">Active</SortableTh>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="is_landing_category"
                        class="text-center">Landing Category</SortableTh>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="parts_count"
                        class="text-center">Parts</SortableTh>
            <th></th>
        </template>
        <template #rows="{ data }">
            <tr v-for="category in data"
                :key="category.id">
                <td>{{ category.title }}</td>
                <td class="text-center">
                    <template v-if="category.is_active">
                        <i class="fa fa-check text-success"></i>
                    </template>
                    <template v-else>
                        <i class="fa fa-times"></i>
                    </template>
                </td>
                <td class="text-center">
                    <template v-if="category.is_landing_category">
                        <i class="fa fa-check text-success"></i>
                    </template>
                    <template v-else>
                        <i class="fa fa-times"></i>
                    </template>
                </td>
                <td class="text-center">{{ category.parts_count }}</td>
                <td v-html="category.actions"></td>
            </tr>
        </template>
    </Datatable>
</template>
