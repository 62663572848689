<template>
    <div class="row mb-4 datatable-options">
        <div class="col-md-2">
            <select class="form-select"
                    v-model.number="limit">
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
            </select>
        </div>
        <div class="col ms-auto mt-2 mt-md-0">
            <div class="input-group">
                <input type="text"
                       class="form-control"
                       v-model="searchTerm"
                       placeholder="Search all addresses..">
                <span class="input-group-text bg-body">
                    <i class="fa fa-search"></i>
                </span>
            </div>
        </div>
    </div>

    <datatable :url="ajaxUrl"
               :limit="limit"
               :orderBy="orderBy"
               :orderAsc="orderAsc"
               :search="searchTerm"
               refreshOn="addresses-list:refresh">
        <template #headings>
            <th :currentOrderBy="orderBy">Address</th>
            <th v-if="showActions">
                <a class="btn btn-sm flex-fill flex-md-grow-0 text-nowrap btn-primary ajax-modal"
                   :href="editUrl"
                   data-bs-class="address-modal"
                   style="letter-spacing: normal; text-transform: none;">
                    <i class="fa fa-fw fa-plus"></i> Add
                </a>
            </th>
            <th v-if="slots.customActions"></th>
        </template>
        <template #rows="{ data }">
            <tr v-for="address in data"
                :key="address.id">
                <td v-html="addressFormat(address, true, ', ')"></td>
                <td v-if="showActions"
                    v-html="address.actions"></td>
                <td v-if="slots.customActions">
                    <slot name="customActions" :address="address">
                    </slot>
                </td>
            </tr>
        </template>
    </datatable>
</template>

<script setup>
    import datatable from '@/components/admin/utilities/datatable.vue';
    import { addressFormat } from "@/utilities/addressFormat.js";
    import { ref, useSlots } from "vue";

    const slots = useSlots();

    const props = defineProps({
        ajaxUrl: String,
        editUrl: String,
        showActions: {
            type: Boolean,
            default: true,
        },
    });

    const orderBy = ref(null);
    const orderAsc = ref(true);
    const limit = ref(50);
    const searchTerm = ref(null);

    const setOrder = function (input) {
        if (orderBy.value == input) {
            orderAsc.value = !orderAsc.value;
        } else {
            orderBy.value = input;
            orderAsc.value = true;
        }
    };
</script>
