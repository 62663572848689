<template>
    <div class="row mb-4 datatable-options">
        <div class="col-md-2">
            <select class="form-select"
                    v-model.number="limit">
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
            </select>
        </div>
        <div class="col ms-auto mt-2 mt-md-0">
            <div class="input-group">
                <input type="text"
                       class="form-control"
                       v-model="searchTerm"
                       placeholder="Search all shipments..">
                <span class="input-group-text bg-body">
                    <i class="fa fa-search"></i>
                </span>
            </div>
        </div>
    </div>

    <datatable :url="ajaxUrl"
               :limit="limit"
               :orderBy="orderBy"
               :orderAsc="orderAsc"
               :search="searchTerm"
               refreshOn="shipments-list:refresh">
        <template #headings>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="id">ID</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="courier">Courier</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="tracking_number">Tracking number</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="customer">Customer</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="address">Address</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="shipped_at">Shipped</sortableTh>
            <th></th>
        </template>
        <template #rows="{ data }">
            <tr v-for="shipment in data"
                :key="shipment.id">
                <td>{{ shipment.id }}</td>
                <td>{{ shipment.courier }}</td>
                <td v-html="shipment.tracking_number"></td>
                <td>{{ shipment.customer }}</td>
                <td>{{ shipment.address }}</td>
                <td>{{ shipment.shipped_at }}</td>
                <td v-html="shipment.actions"></td>
            </tr>
        </template>
        <template #smallScreenLayout="{ data }">
            <div v-for="shipment in data"
                :key="shipment.id"
                class="list-group-item">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">Shipment {{ shipment.id }}</h5>
                    <small>{{ shipment.shipped_at }}</small>
                </div>
                <p class="mb-1">{{ shipment.address }}</p>
                <small>{{ shipment.courier }}</small>
                <small class="d-block" v-html="shipment.tracking_number"></small>
                <div class="mt-2" v-html="shipment.actions"></div>
            </div>
        </template>
    </datatable>
</template>

<script setup>
    import datatable from '@/components/admin/utilities/datatable.vue';
    import sortableTh from '@/components/admin/datatable/sortableTh.vue';
    import { ref } from "vue";

    const props = defineProps({
        ajaxUrl: String,
    });

    const orderBy = ref('id');
    const orderAsc = ref(false);
    const limit = ref(50);
    const searchTerm = ref(null);

    const setOrder = function (input) {
        if (orderBy.value == input) {
            orderAsc.value = !orderAsc.value;
        } else {
            orderBy.value = input;
            orderAsc.value = true;
        }
    };
</script>
