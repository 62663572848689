<script setup>
import FormSelect from "@/components/admin/utilities/formSelect.vue";
import RefundItemsTable from "@/components/admin/refunds/components/RefundItemsTable.vue";
import {computed, inject, nextTick, onMounted, ref} from "vue";

const props = defineProps({
    routes: {
        type: Object,
        default: () => ({}),
    },
    errors: {
        type: Object,
        default: () => ({}),
    },
    old: {
        type: Object,
        default: () => ({}),
    },
    refund: {
        type: Object,
        default: null,
    },
});

const csrfToken = inject('csrfToken');
const selectedCustomerOrder = ref(null);
const refundItems = ref(null);
const creditNoteNumber = ref(props.refund?.credit_note_number);
const canSubmit = computed(() => {
    return refundItems.value && refundItems.value.selectedItems.length;
});

onMounted(() => {
    if (props.old.customer_order) {
        nextTick(() => {
            selectedCustomerOrder.value = props.old.customer_order;
        });
    }

    if (props.refund) {
        nextTick(() => {
            selectedCustomerOrder.value = {
                label: `#${props.refund.customer_order.id} for ${props.refund.customer_order.customer.name}`,
                value: props.refund.customer_order.id,
            };
        });
    }
});
</script>

<template>
    <form method="POST" :action="refund ? routes.update : routes.store">
        <input v-if="refund" type="hidden" name="_method" value="PATCH" />
        <input type="hidden" name="_token" :value="csrfToken" />
        <div class="form-group mb-4">
            <label for="customer_order" class="control-label">Customer order</label>
            <FormSelect
                name="customer_order"
                v-model="selectedCustomerOrder"
                :url="routes.customerOrdersList"
                :class="{'is-invalid': errors.customer_order}"
                :disabled="refund !== null"
            />
            <div v-if="errors.customer_order" class="invalid-feedback">
                {{ errors.customer_order[0] }}
            </div>
        </div>
        <div class="form-group mb-4"
             v-show="selectedCustomerOrder">
            <label for="credit_note_number" class="control-label">Credit note number</label>
            <input class="form-control" maxlength="255" name="credit_note_number" type="text" v-model="creditNoteNumber" id="credit_note_number">
        </div>
        <RefundItemsTable
            v-show="selectedCustomerOrder"
            ref="refundItems"
            :customer-order="selectedCustomerOrder?.value"
            :refund-items-url="routes.refundItems"
            :current-total-refund="refund?.total"
        />
        <div class="text-end my-3">
            <a :href="routes.refundsIndex" class="btn btn-secondary me-2">Cancel</a>
            <button type="submit" class="btn btn-primary" :disabled="!canSubmit">
                {{ refund ? 'Update' : 'Issue' }} Refund
            </button>
        </div>
    </form>
</template>
